// ==================================================
//  メインビジュアル（Mainvisual）
// ==================================================
// スライダー
document.addEventListener("DOMContentLoaded", function () {
  // スライダー
  const mainvisualSliderElm = document.querySelector(".js-mainvisualSlider");
  new Splide(mainvisualSliderElm, {
    type: "loop",
    arrows: false,
    pagination: false,
    focus: "center",
    autoScroll: {
      speed: 1.2,
      pauseOnHover: false,
      pauseOnFocus: false,
    },
    autoWidth: true,
    autoHeight: true,
    drag: false,
    breakpoints: {
      767: {
        autoScroll: {
          speed: 0.8,
        },
      },
    },
  }).mount(window.splide.Extensions);
});
